import {List, Divider} from "@mui/material";
import DrawerEntry from "./DrawerEntry";
import {MenuItems} from "config/drawer-items";
import CustomDrawerHeader from "./CustomDrawerHeader";
import ThemeColors from "config/colors";
import * as React from "react";
import {styled} from "@mui/material/styles";
import MuiDrawer from "@mui/material/Drawer";
import CustomDrawerFooter from "./CustomDrawerFooter";

const drawerWidth = 240;

const openedMixin = (theme) => ({
    width: drawerWidth,
    overflowX: 'hidden',
    transition: theme.transitions.create('width', {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.enteringScreen,
    }),
});

const closedMixin = (theme) => ({
    width: `calc(${theme.spacing(6)})`,
    zIndex: 1201,
    transition: theme.transitions.create('width', {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.leavingScreen,
    }),
});

const Drawer = styled(MuiDrawer, {shouldForwardProp: (prop) => prop !== 'open'})(
    ({theme, open}) => ({
        width: drawerWidth,
        flexShrink: 0,
        whiteSpace: 'nowrap',
        boxSizing: 'border-box',
        background: ThemeColors.whiteColor,
        ...(open && {
            ...openedMixin(theme),
            '& .MuiDrawer-paper': openedMixin(theme),
        }),
        ...(!open && {
            ...closedMixin(theme),
            '& .MuiDrawer-paper': closedMixin(theme),
        }),

        // '&:hover': {
        //     ...openedMixin(theme),
        //     '& .MuiDrawer-paper': {
        //         ...openedMixin(theme),
        //         '&::-webkit-scrollbar': {
        //             width: '12px',
        //         },
        //         '&::-webkit-scrollbar-track': {
        //             background: '#eee',
        //         },
        //         '&::-webkit-scrollbar-thumb': {
        //             background: ThemeColors.greyColor,
        //             borderRadius: 8,
        //         },
        //     },
        // },
        '& .MuiDrawer-paper::-webkit-scrollbar': {
            width: '0',
        },
    }),
);

const CustomDrawer = ({open, handleDrawerClose }) => {
    return (
        <Drawer variant="permanent" open={open}>
            <CustomDrawerHeader handleDrawerClose={handleDrawerClose}/>
            <Divider/>
            <List sx={{
                '& .MuiListItem-root.Mui-selected, & .MuiListItem-root:hover': {
                    color: `${ThemeColors.brandingRed}`,
                },
            }}>
                {MenuItems?.map((item) => (
                    <DrawerEntry key={item.id} item={item}/>
                ))}
            </List>
            {open && <>
                <Divider/>
                <CustomDrawerFooter/>
            </>
            }
        </Drawer>
    );
};

export default CustomDrawer;
